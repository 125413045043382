import {FontItem, FontStorageState} from '../../interfaces/screens/fontChanger';

export const fontSizeOptions: Array<FontItem> = [
  {
    value: 1,
    label: '14',
    textSize: 14,
  },
  {
    value: 2,
    label: '17',
    textSize: 17,
  },
  {
    value: 3,
    label: '20',
    textSize: 20,
  },
  {
    value: 4,
    label: '24',
    textSize: 24,
  },
];

export const initialFontReducer: FontStorageState = {
  item: fontSizeOptions[1],
  fontSize: fontSizeOptions[1]?.textSize,
  fontWeight: 'normal',
};
