import useWindowDimensions from './hooks/useWindowDimensions';

/**
 * Returns the value based on the screen height percentage, with dynamic resizing support.
 * @param {number} percent - The percentage value relative to the screen height.
 * @returns {number} - The calculated value based on the screen height.
 */
export const hdp = (percent: number): number => {
  const {height} = useWindowDimensions();
  return (percent * height) / 100;
};

/**
 * Returns the value based on the screen width percentage, with dynamic resizing support.
 * @param {number} percent - The percentage value relative to the screen width.
 * @returns {number} - The calculated value based on the screen width.
 */
export const wdp = (percent: number): number => {
  const {width} = useWindowDimensions();
  return (percent * width) / 100;
};

/**
 * Returns a value based on the screen size (responsive logic), with dynamic resizing support.
 * @param {any} smValue - The value to be used for small screens.
 * @param {any} lgValue - The value to be used for large screens.
 * @param {any} [xsmValue=smValue] - The value to be used for extra small screens (optional).
 * @returns {any} - The calculated value based on the screen size.
 */
export const getByScreenSize = (
  smValue: any,
  lgValue: any,
  xsmValue: any = smValue,
): any => {
  const {width} = useWindowDimensions();
  return width < 480 ? xsmValue : width < 768 ? smValue : lgValue;
};

/**
 * Returns a value based on the screen height (responsive logic), with dynamic resizing support.
 * @param {any} smValue - The value to be used for smaller screen heights.
 * @param {any} lgValue - The value to be used for larger screen heights.
 * @returns {any} - The calculated value based on the screen height.
 */
export const getByScreenHeight = (smValue: any, lgValue: any): any => {
  const {height} = useWindowDimensions();
  return height < 800 ? smValue : lgValue;
};
