import React, {memo, useState} from 'react';
import styled, {css, useTheme} from 'styled-components';
import {HeaderTabsProps} from '../../interfaces/components/headerTabs';
import Icon from '../icon';
import {Theme} from '../../theme/lightTheme';
import ToggleMenuIcon from '../toggleMenuIcon';
import Header from '../header';
import {tr} from '../../translation';
import {motion, useMotionValueEvent, useScroll} from 'framer-motion';
import {IoIosArrowBack} from 'react-icons/io';
import {useNavigate} from 'react-router-dom';

const iconSize = (isMobile: boolean) => (isMobile ? '2rem' : '2.5rem');

const Container = styled(motion.nav)`
  padding: 1rem ${({theme}) => iconSize(theme.isMobile)};
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  position: sticky;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  top: 0;
  z-index: 1000;
  ${({theme}) =>
    theme.isMobile &&
    css`
      background-color: ${theme.isMobile
        ? theme.homeBackground
        : 'transparent'};
      background: linear-gradient(
        to bottom,
        ${theme.homeBackground + '55'},
        ${theme.homeBackground + '11'}
      );
      backdrop-filter: blur(8px);
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
      border-bottom: 1px solid ${theme.text.borderFocus + '22'};
    `};
`;
const IconContainer = styled.div`
  width: ${({theme}) => `calc(${iconSize(theme.isMobile)} * 2)`};
  aspect-ratio: 2;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  &:active {
    opacity: 0.9;
  }
`;
export const BackIconContainer = styled(motion.button)`
  width: ${({theme}) => `calc(${iconSize(theme.isMobile)} * 1.2)`};
  aspect-ratio: 1;
  background: linear-gradient(
    to right,
    ${({theme}) => theme.text.light + '22'},
    ${({theme}) => theme.text.light + '44'}
  );
  backdrop-filter: blur(2px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  margin-right: auto;
`;
const SearchContainer = styled(motion.div)`
  display: flex;
  align-items: center;
  height: ${({theme}) => `calc(${iconSize(theme.isMobile)} / 1.1)`};
  width: ${({theme}) => theme.dimensions.wdp(15)}px;
  border: 1px solid ${({theme}) => theme.text.grey + 'AA'};
  border-radius: 5px;
  box-sizing: border-box;
  ${({theme}) =>
    theme.isMobile
      ? css`
          flex: 1;
          margin: 0 4%;
        `
      : css`
          position: absolute;
          right: calc(${iconSize(theme.isMobile)} * 2.4);
        `};
  @media (max-width: 1000px) {
    width: 3rem;
    right: calc(${iconSize(false)} * 2);
  }
`;
const SearchText = styled.p`
  font-style: italic;
  font-size: ${({theme}) => theme.text.s10};
  padding: 0.5rem;
  flex: 1;
  color: ${({theme}) => theme.text.grey + 'CC'};
  @media (max-width: 1000px) {
    display: ${({theme}) => (theme.isMobile ? 'flex' : 'none')};
  }
`;
const SearchIconContainer = styled.div`
  height: 100%;
  padding: 0 0.7rem;
  align-items: center;
  justify-content: center;
  display: flex;
  background-color: ${({theme}) => theme.text.grey + '11'};
`;

export const navVariants = {
  visible: {opacity: 1, y: 0},
  hidden: {opacity: 0, y: '-4rem'},
};

/**
 * The HeaderTabs component.
 * Renders a header with title, back button, and optional right icon.
 *
 * @param {HeaderTabsProps} props - The header component props.
 * @returns {JSX.Element} The rendered header component.
 */
const HeaderTabs = ({
  style,
  showHeaderTabs = true,
  showMenu = true,
  showSearch = true,
  showLogo = true,
  showBack = false,
  onSearchPress,
  onMenuPress,
  isSidebarOpen,
  onBackPress,
}: HeaderTabsProps): JSX.Element => {
  const theme = useTheme() as Theme;
  const navigate = useNavigate();
  const {scrollY} = useScroll();
  const [hidden, setHidden] = useState(false);
  const [prevScroll, setPrevScroll] = useState(0);

  const update = (latest: number, prev: number): void => {
    if (latest < prev) {
      setHidden(false);
    } else if (latest > 100 && latest > prev) {
      setHidden(true);
    }
  };

  useMotionValueEvent(scrollY, 'change', (latest: number) => {
    update(latest, prevScroll);
    setPrevScroll(latest);
  });

  const handleMenuPress = async () => {
    onMenuPress && onMenuPress();
  };

  const handleBackPress = () => {
    if (onBackPress) {
      onBackPress();
    } else {
      navigate(-1);
    }
  };

  return (
    <Container
      variants={navVariants}
      animate={hidden && !theme.isMobile ? 'hidden' : 'visible'}
      transition={{
        type: 'spring',
      }}
      style={style}>
      <IconContainer>
        {showLogo && !showBack && <Icon name={'logo'} size={'100%'} />}
        {showBack && (
          <BackIconContainer
            onClick={handleBackPress}
            whileTap={{
              opacity: 0.9,
              scale: 0.9,
              shadow: `0 8px 16px ${theme.shadow}`,
            }}>
            <IoIosArrowBack color={theme.secondary} size={'70%'} />
          </BackIconContainer>
        )}
      </IconContainer>
      {showHeaderTabs && !theme.isMobile && <Header />}
      {showSearch && (
        <SearchContainer
          whileTap={{scale: 0.98, opacity: 0.9}}
          onClick={() => onSearchPress && onSearchPress()}>
          <SearchText>{tr('search.placeholder')}</SearchText>
          <SearchIconContainer>
            <Icon name={'search'} size={'70%'} color={theme.text.grey} />
          </SearchIconContainer>
        </SearchContainer>
      )}
      <IconContainer>
        {showMenu && (
          <ToggleMenuIcon
            iconSize={theme.isMobile ? '1.5rem' : '2rem'}
            isOpen={isSidebarOpen}
            onClick={handleMenuPress}
          />
        )}
      </IconContainer>
    </Container>
  );
};

export default memo(HeaderTabs);
