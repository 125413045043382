import * as React from 'react';
import {SVGProps} from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => {
  const key = Object.values(props || [])
    .join('')
    .replaceAll('%', '');

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={66}
      height={72}
      viewBox={'0 0 66 72'}
      {...props}
      fill="none"
      stroke="none">
      <g filter={`url(#a${key})`} opacity={0.5}>
        <path
          fill={`url(#b${key})`}
          d="M61.124 45.53V18.01c.025-1.33-.611-2.67-1.942-3.432L34.504.515a3.937 3.937 0 0 0-3.893 0L5.942 14.577A3.793 3.793 0 0 0 4 17.892v27.756a3.788 3.788 0 0 0 1.917 3.206l24.669 14.272a3.934 3.934 0 0 0 3.935 0l24.67-14.272a3.789 3.789 0 0 0 1.916-3.206v-.117h.017Z"
          shapeRendering="crispEdges"
        />
      </g>
      <defs>
        <linearGradient
          id={`b${key}`}
          x1={32.562}
          x2={32.562}
          y1={63.653}
          y2={0.004}
          gradientUnits="userSpaceOnUse">
          <stop stopColor={props.fill || '#fff'} />
          <stop
            offset={1}
            stopColor={props.stroke || '#fff'}
            stopOpacity={props.stroke ? 1 : 0}
          />
        </linearGradient>
        <filter
          id={`a${key}`}
          width={65.124}
          height={71.653}
          x={0}
          y={0}
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse">
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy={4} />
          <feGaussianBlur stdDeviation={2} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1_6434"
          />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy={4} />
          <feGaussianBlur stdDeviation={2} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0" />
          <feBlend
            in2="effect1_dropShadow_1_6434"
            result="effect2_dropShadow_1_6434"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect2_dropShadow_1_6434"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
export default SvgComponent;
