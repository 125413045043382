import {FontStorageState} from '../../../interfaces/screens/fontChanger';
import {store} from '../../../redux/store';
import {setConfig} from '../../auth/login/reducer';
import {getLocalData, setLocalData} from '../../../utils/storage';
import {keys} from '../../../api/keys';
import {initialFontReducer} from '../../../assets/static/fontChanger';

/**
 * this function to update the website font
 *
 * @return {boolean} result update if it's success
 */
export const updateAppFont = async (
  font?: FontStorageState,
  isInitialize?: boolean,
): Promise<boolean> => {
  try {
    let tempFont = font;
    if (isInitialize) {
      tempFont = await getFont();
    } else if (tempFont) {
      await updateFont(tempFont);
    }
    const {config} = store.getState().auth || {};
    if (!tempFont) {
      return false;
    }
    const tempConfig = {...(config || {}), font: tempFont};
    store.dispatch(setConfig(tempConfig));
    return true;
  } catch (error: any) {
    if (process.env.REACT_APP_DEBUG === 'true') {
      console.info('Update App Font FAIL: ', error);
    }
    return false;
  }
};

export const updateFont = async (data: FontStorageState) => {
  try {
    await setLocalData(keys.FONT, JSON.stringify(data));
    return true;
  } catch (err) {
    return Promise.reject(err);
  }
};

export const getFont = async (): Promise<FontStorageState> => {
  try {
    const data = await getLocalData(keys.FONT);
    return data ? JSON.parse(data) : initialFontReducer;
  } catch (err) {
    return Promise.reject(err);
  }
};
