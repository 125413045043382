import React, {useState, useEffect, useRef} from 'react';
import TextInput from '../textInput';
import moment from 'moment';
import {GenericDatePickerProps} from '../../interfaces/components/datePicker';
import MyPressable from '../myPressable';
import {Calendar} from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
`;
const SlideContainer = styled.div<{show: boolean}>`
  position: absolute;
  transition:
    opacity 0.3s ease-in-out,
    transform 0.3s ease-in-out;
  opacity: 0;
  z-index: 999;
  transform: translateY(-5%);
  pointer-events: none;
  overflow: hidden;
  border-radius: 16px;
  ${({show}) =>
    show &&
    `
      opacity: 1;
      transform: translateY(0);
          pointer-events:auto;

    `};
  background-color: ${({theme}) => theme.text.light};
`;

/**
 * A generic date picker component.
 *
 * @param className
 * @param label
 * @param value
 * @param onChange
 * @param style
 * @param format
 * @param maxDate
 * @param minDate
 * @param disabled
 * @param isRequired
 * @param {GenericDatePickerProps} props - The date picker component props.
 * @returns {JSX.Element} The rendered date picker component.
 */
const GenericDatePicker = ({
  className,
  label,
  value,
  onChange,
  style,
  format,
  maxDate,
  minDate,
  disabled,
  isRequired,
  ...props
}: GenericDatePickerProps): JSX.Element => {
  const [selectedDate, setSelectedDate] = useState<string | Date | undefined>(
    value,
  );
  const [show, setShow] = useState(false);
  const slideRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent | TouchEvent) {
      if (
        slideRef.current &&
        !slideRef.current.contains(event.target as Node)
      ) {
        setShow(false);
      }
    }

    // Attach mouseup and touchend event listeners to document (instead of mousedown/touchstart)
    document.addEventListener('mouseup', handleClickOutside);

    return () => {
      // Cleanup the event listeners on unmount
      document.removeEventListener('mouseup', handleClickOutside);
    };
  }, [slideRef]);

  useEffect(() => {
    setSelectedDate(value);
  }, [value]);

  /**
   * Handles the change event when a date is selected.
   *
   * @param {Date} selectedDate - The selected date.
   * @returns {void}
   */
  const onChangeHandler = (selectedDate: Date): void => {
    const date = moment(selectedDate).format(format);
    setSelectedDate(selectedDate);
    onChange && onChange(date);
    setShow(false);
  };

  /**
   * Retrieves the formatted value to display in the text input.
   *
   * @returns {string} The formatted value.
   */
  const getValue = (): string => {
    if (!value && !selectedDate) {
      return '';
    }
    return moment(selectedDate, format, true).format(format);
  };

  /**
   * Toggle the date picker when the input is tapped.
   */
  const toggleDatePicker = () => {
    setShow(prev => !prev);
  };

  return (
    <Container className={className} ref={slideRef}>
      <MyPressable
        disableOpacityAnimation
        disableRipple
        disableScale
        disableOpacity={false}
        style={{
          ...style,
          ...(disabled ? {opacity: 0.5} : {}),
          ...{padding: 0},
        }}
        onClick={toggleDatePicker}>
        <TextInput
          label={label}
          value={getValue()}
          showRightIcon
          rightIconName={'calendar'}
          rightIconType={'SVG'}
          isRequired={isRequired}
          style={{...style, ...{pointerEvents: 'none'}}}
          {...props}
        />
      </MyPressable>
      <SlideContainer show={show}>
        <Calendar
          key={label}
          format={'DD-MM-YYYY'}
          onChange={onChangeHandler}
          maxDate={!maxDate ? undefined : new Date(maxDate)}
          minDate={!minDate ? undefined : new Date(minDate)}
          date={
            selectedDate
              ? new Date(moment(selectedDate, format).format())
              : undefined
          }
          fixedHeight
        />
      </SlideContainer>
    </Container>
  );
};

/**
 * Default props for the GenericDatePicker component.
 *
 * @type {GenericDatePickerProps}
 */
GenericDatePicker.defaultProps = {
  containerStyle: {},
  format: 'DD-MM-YYYY',
  maxDate: '',
  value: '',
};

export default GenericDatePicker;
